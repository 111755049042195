.pageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E0E1EB;
  min-height: 100vh;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between title and image */
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px; /* Space between the back button and the title */
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px; /* Space between back button and text */
  flex-grow: 1; /* Ensures the text container takes up available space */
}

.feedViewerVersion {
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 24px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto; /* Ensures the image stays on the right */
}

.content {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.auditTitle {
  font-size: 20px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-bottom: 15px;
}

.auditTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed; /* Ensures equal width for all columns */
  margin-bottom: 20px;
}

.auditTable th,
.auditTable td {
    padding: 10px;
    border: 1px solid #ddd;
}

.auditTable th {
    background-color: #f8f8f8;
    color: #000000;  /* Change header text color to black */
    font-weight: bold;
    position: relative;
    padding-right: 30px;
}

.auditTable td {
    background-color: #ffffff;
    color: #292B3D;
}

.auditTable td span {
  font-size: 12px;
  color: #888;
  display: block;
  margin-top: 5px;
}

.auditTable td:nth-child(2) {  /* Empty */
  background-color: #ffcccc;  /* Light red */
}

.auditTable td:nth-child(3) {  /* <25 */
  background-color: #ffe6e6;  /* Red */
}

.auditTable td:nth-child(4) {  /* 25-49 */
  background-color: #ffedcc;  /* Orange */
}

.auditTable td:nth-child(5) {  /* 50-74 */
  background-color: #ccf2e5;  /* Light teal */
}

.auditTable td:nth-child(6) {  /* >75 */
  background-color: #b3e6cc;  /* Light green */
}

.divider {
  border: none;           /* Remove default border */
  border-top: 1px solid #ccc;  /* Custom border style */
  margin: 30px 0;          /* Spacing around the divider */
}

.auditTable .missing-attribute {
  background-color: #f9f9f9;
}

.auditTable .missing-attribute-percentage {
  font-weight: bold;
  color: #ff4d4d;
}

.error {
  color: red;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
}

.languageSelector {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust space between the language dropdown and the input field */
}

.languageSelector label {
  margin-right: 10px;
  width: 150px; /* Ensure this matches the width of other labels */
}

.languageSelector select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.configurationContainer,
.auditResult {
  width: calc(100% - 40px); /* Subtracting 40px to account for 20px padding on both sides */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.colorInputContainer {
  display: flex;
  align-items: center;
  flex: 1; /* Allow the input field to take the remaining space */
}

.colorInputContainer label {
  margin-right: 10px;
  font-weight: bold;
}

.colorInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.applyFilterButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  width: 100px; /* Set a fixed width that works for both the icon and spinner */
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.applyFilterButton:hover {
  background-color: #4758EB; /* Darker background on hover */
}

.brandInputContainer {
  margin-bottom: 20px;
}

.brandInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.genderInputContainer {
  margin-bottom: 20px;
}

.genderInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.warningMessage {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.inputContainer label {
  width: 150px; /* Set a fixed width for the label */
  margin-right: 10px;
}

.inputField {
  flex: 1; /* Allow the input field to take the remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.languageAndColorContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  background-color: #d0d0d0; /* Same background color as the applyFilterButton */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
}

.backButton:hover {
  background-color: #7c7c7c; /* Darker background on hover */
}

.countOfProductsContainer {
  width: fit-content; /* Same width as auditResult containers */
  margin-left: 20px;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.countOfProducts {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-weight: 600;
  width: 100%;
}

.itemsFoundValue {
  font-size: 22px; 
  color: #4758EB;
  font-weight: 700;
  display: inline-block;
  margin-left: 5px;
}

/* Specific styles for the Attribute Audit table */
.attributeAuditTable {
  width: 100%; /* Make the table use the full width */
  border-collapse: collapse;
  text-align: left; /* Left align the text in table cells */
  table-layout: auto; /* Allow the table to adjust column width based on content */
}

.attributeAuditTable th,
.attributeAuditTable td {
  padding: 10px;
  border: 1px solid #ddd;
  color: #292B3D;
}

.attributeAuditTable th {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f8f8f8; /* Override any existing background color */
  color: #292B3D;
}

.attributeAuditTable .attributeColumn {
  width: 25%; /* Attribute column takes 25% of the width */
}

.attributeAuditTable .smallColumn {
  width: 15%; /* The existing width setting */
  text-align: center; /* Center text horizontally */
  font-weight: bold;
}

.attributeAuditTable .frequentColumn {
  width: 45%; /* Top 3 Most Frequent column takes 45% of the width */
  text-align: left;
  position: relative; /* Allows positioning the button to the right */
  white-space: normal; /* Allow line breaks */
}

.attributeAuditTable .frequentValue {
  margin-bottom: 5px; /* Adds some space between top frequent values */
  word-wrap: break-word; /* Breaks long words to fit within the column */
}

.attributeAuditTable .moreButton {
  background-color: #848EE0; /* Same background color as the backButton */
  color: #ffffff; /* White icon color */
  border: none;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
  position: absolute; /* Position the button absolutely */
  right: 20px; /* Align the button to the right */
  top: 50%; /* Vertically center the button */
  transform: translateY(-50%); /* Adjust for proper centering */
}

.attributeAuditTable .moreButton:hover {
  background-color: #4758EB; /* Darker background on hover */
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
}

.popupContent h3 {
  margin-top: 0;
  font-size: 18px;
  color: #4758EB;
  text-align: center;
}

.popupContent p {
  text-align: center; /* Centering the paragraph */
}

.popupTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.popupTable th,
.popupTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.popupTable th {
  background-color: #f5f5f5;
  color: #4758EB;
  font-size: 14px;
}

.popupTable td {
  font-size: 14px;
  color: #292B3D;
}

.popupTable tr:hover {
  background-color: #f0f0f0;
}

.closePopupButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closePopupButton:hover {
  background-color: #6577C7;
}

.closeIconButton {
  position: sticky; /* Make the button sticky */
  top: 10px; /* Align it to the top of the container */
  background: none;
  border: none;
  color: #6577C7;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin-left: auto; /* Push it to the right */
}

.closeIconButton:hover {
  color: #4758EB;
}

.auditHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px; /* Space between the dropdowns */
  flex-wrap: wrap; /* Ensure they wrap if there's not enough space */
}

.attributeDropdown {
  margin-left: 32px;
  flex-shrink: 0; /* Prevent the dropdown from shrinking */
  min-width: 200px;
}

.customSelect {
  flex-shrink: 0; /* Prevent the multi-select from shrinking */
  min-width: 500px;
  max-width: 800px; /* Limit the maximum width */
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the buttons */
}

.downloadButton {
  background-color: #848EE0; /* Same background color as the backButton */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
}

.downloadButton:disabled {
  background-color: #d0d0d0; /* Greyed out when disabled */
  cursor: not-allowed;
}

.downloadButton:hover:not(:disabled) {
  background-color: #4758EB; /* Darker background on hover, only when not disabled */
}

.smallTableButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  opacity: 0.8;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.smallTableButton:disabled {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

.smallTableButton:hover:not(:disabled) {
  background-color: #4758EB;
  opacity: 1;
  transform: translateY(-50%) scale(1.05);
}

.addSizeSystemButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #4758EB; /* or your brand color */
  transition: color 0.3s ease;
}

.addSizeSystemButton:hover {
  color: #848EE0;
}

/* Ensures the input and button share the same row & full width */
.sizeInputWrapper {
  display: flex;
  align-items: center;
  flex: 1; /* Let this fill the available space next to the label */
  position: relative; /* Only if you need absolute positioning for the dropdown */
}

.sizeInputWrapper input.inputField {
  flex: 1;      /* Instead of a fixed width */
  margin-right: 8px;
}

/* The dropdown that appears when user clicks the plus icon */
.sizeMenu {
  position: absolute;
  top: 40px;  /* Adjust so it sits just under your input/button row */
  right: 0;   /* Align to the right side of the wrapper */
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 120px;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

.menuOption {
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.menuOption:hover {
  background-color: #f0f0f0;
}

/* Style for the "Found in" tables */
.foundInTable td {
  background-color: #ffffff;  /* All cells white background */
}

/* Common table styles */
.auditTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed;
  margin-bottom: 20px;
}

.auditTable th,
.auditTable td {
  padding: 10px;
  border: 1px solid #ddd;
}

.auditTable th {
  background-color: #f8f8f8;
  color: #000000;
  font-weight: bold;
  position: relative;
  padding-right: 30px;
}

.sectionTitle {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #4758EB;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 600;
}

/* Keep only the Length Audit tables colored */
.lengthAuditTable td:nth-child(2) {  /* Empty */
  background-color: #ffcccc;  /* Light red */
}

.lengthAuditTable td:nth-child(3) {  /* 1-24 or 1-99 */
  background-color: #ffe6e6;  /* Red */
}

.lengthAuditTable td:nth-child(4) {  /* 25-49 or 100-499 */
  background-color: #ffedcc;  /* Orange */
}

.lengthAuditTable td:nth-child(5) {  /* 50-74 or 500-1499 */
  background-color: #ccf2e5;  /* Light teal */
}

.lengthAuditTable td:nth-child(6) {  /* >75 or >1500 */
  background-color: #b3e6cc;  /* Light green */
}


.auditTable.lengthAuditTable td:nth-child(2) {
  background-color: #ffcccc; /* Light red for "Empty" column */
}

.auditTable.lengthAuditTable td:nth-child(3) {
  background-color: #ffe6e6; /* Red for 1-24 or 1-99 column */
}

.auditTable.lengthAuditTable td:nth-child(4) {
  background-color: #ffedcc; /* Orange for 25-49 or 100-499 column */
}

.auditTable.lengthAuditTable td:nth-child(5) {
  background-color: #ccf2e5; /* Light teal for 50-74 or 500-1499 column */
}

.auditTable.lengthAuditTable td:nth-child(6) {
  background-color: #b3e6cc; /* Light green for >=75 or >=1500 column */
}


/* ========================================== */
/* CUSTOM ATTRIBUTE AUDIT SECTION: IMPROVEMENTS */
/* ========================================== */

/* Container for the custom attribute section's controls (select + intervals) */
.customAuditControlsRow {
  display: flex;
  align-items: flex-end; /* or center */
  gap: 20px;
  margin-bottom: 10px;
}

/* Wrapping the label + react-select neatly */
.attributeSelectorContainer {
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.attributeSelectorLabel {
  margin-bottom: 5px;
  font-weight: 500;
  color: #292B3D;
}

/* The container for intervals input rows */
.intervalsGridContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
}

.intervalsHeader {
  margin-bottom: 10px;
  font-weight: 500;
  color: #292B3D;
}

/* Each row for a single interval range */
.intervalRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Label for "Interval 1 / Interval 2" etc. */
.intervalLabel {
  width: 80px;
  margin-right: 8px;
  font-weight: bold;
  color: #292B3D;
}

/* The dash or "to" between min and max */
.intervalDash {
  margin: 0 10px;
  color: #888;
}

/* The numeric input fields for min/max */
.intervalInput {
  width: 50px;                /* Adjust as needed */
  height: 24px;               /* So it matches your other inputs' height */
  padding: 0 8px;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  background-color: #f8f8f8;   /* Same as your Select control background */
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;          /* So numbers appear centered */
  transition: border-color 0.3s;
}
.intervalInput:hover {
  border-color: #848EE0;
}
.intervalInput:focus {
  outline: none;              /* Remove default highlight */
  border-color: #4758EB;      /* Same color used on other focus states */
}


/* Keep hidden input visually out of sight for the "1500+" scenario */
.hiddenInput {
  opacity: 0;
  pointer-events: none;
  width: 0;
}

/* We re-use .applyFilterButton for “Run Audit”, so no new class needed there.
   If you want minor differences, you could define a new .runCustomAuditButton
   that extends it, but it’s not mandatory. */

/* Additional minor style improvement for the entire custom attribute select */
.customAttributeSelect {
  min-width: 250px; /* matches .attributeSelectorContainer */
}


.runCustomAuditIconButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px;
  transition: background-color 0.3s;
}

.runCustomAuditIconButton:hover:not(:disabled) {
  background-color: #4758EB; 
}

.refreshNote {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
  margin-bottom: 15px;
  font-style: italic;
}
