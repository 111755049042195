.loginContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f2fa; /* Lighter, more modern background */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Lexend Deca', sans-serif;
  padding: 40px 20px;
}

/* Hero Section Styling */
.heroSection {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(71, 88, 235, 0.12);
}

.heroContent {
  width: 100%;
  padding-right: 24px;
  text-align: center;
}

.heroTitle {
  font-family: 'DM Serif Text', serif;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #4758EB;
  line-height: 1.2;
}

.heroSubtitle {
  font-size: 20px;
  color: #292B3D;
  margin-bottom: 30px;
  line-height: 1.4;
}

.heroButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-bottom: 20px;
}

/* Consent Container Styling */
.loginConsentContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f9ff;
  margin-top: 20px;
  border: 1px solid #e0e3f5;
}

/* Features Section */
.featuresSection {
  width: 100%;
  max-width: 1400px;
  padding: 60px 20px;
  text-align: center;
  margin-top: 40px;
}

.sectionTitle {
  font-family: 'DM Serif Text', serif;
  font-size: 32px;
  color: #292B3D;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}

.sectionTitle:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #4758EB;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.featuresGrid {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.featureCard {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 35px 25px;
  width: 340px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(71, 88, 235, 0.1);
}

.featureCard:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(71, 88, 235, 0.15);
}

.featureIcon {
  font-size: 40px;
  margin-bottom: 20px;
  display: inline-block;
  background: #f0f2fa;
  height: 80px;
  width: 80px;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
}

.featureCard h3 {
  font-size: 20px;
  color: #4758EB;
  margin-bottom: 15px;
  font-weight: 600;
}

.featureCard p {
  font-size: 16px;
  color: #292B3D;
  line-height: 1.6;
}

/* Button Styling - Keep prominent */
.googleSignInButton {
  font-family: 'DM Sans', sans-serif;
  background-color: #4758EB;
  color: #ffffff;
  border: none;
  padding: 16px 30px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 6px 16px rgba(71, 88, 235, 0.25);
  width: 100%;
  max-width: 300px;
  height: 60px;
  letter-spacing: 0.5px;
}

.googleSignInButton:hover:not(:disabled) {
  background-color: #3a49c9;
  box-shadow: 0 8px 20px rgba(71, 88, 235, 0.35);
  transform: translateY(-3px);
}

.googleSignInButton:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(71, 88, 235, 0.25);
}

.googleSignInButton:disabled {
  background-color: #b3b7db;
  cursor: not-allowed;
  transform: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.7;
}

.googleButtonIcon img {
  width: 24px;
  height: 24px;
  display: block;
}

/* Consent Checkbox */
.consentLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* Let it shrink‐wrap its contents */
  width: auto;
  /* Center it horizontally */
  margin: 0 auto 15px auto;
  /* (Optional) keep the padding & styling you already have */
  padding: 12px;
  background-color: #f0f2fd;
  border-radius: 10px;
  border: 1px solid #e6e9fa;
  transition: background-color 0.2s;
}


.consentLabel:hover {
  background-color: #e6e9fa;
}

.consentCheckbox {
  margin-right: 10px;
  margin-top: 2px;
  accent-color: #4758EB;
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: scale(1.1);
}

.checkboxText {
  font-weight: 500;
  color: #292B3D;
  line-height: 1.4;
}

/* Consent Information Box */
.consentBox {
  background-color: #ffffff;
  border-left: 3px solid #4758EB;
  padding: 15px;
  text-align: left;
  font-size: 14px;
  color: #444444;
  line-height: 1.5;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 20px;
}

.consentBox a {
  color: #4758EB;
  text-decoration: underline;
  font-weight: 500;
  transition: color 0.2s;
}

.consentBox a:hover {
  color: #3a49c9;
  text-decoration: none;
}

.consentBox p:first-child {
  margin-top: 0;
}

.consentBox p:last-child {
  margin-bottom: 0;
}

/* Footer */
.loginFooter {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6a6f8c;
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.loginFooter strong {
  color: #4758EB;
  font-weight: 600;
}

.privacyPolicyLink {
  margin-top: 10px;
  text-align: center;
}

.privacyPolicyLink a {
  color: #3498db;
  font-weight: 500;
  text-decoration: underline;
  font-size: 16px;
}

.privacyPolicyLink a:hover {
  color: #1c73c9;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .heroSection {
    padding: 25px 15px;
  }
  
  .heroTitle {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .featuresGrid {
    flex-direction: column;
    align-items: center;
  }
  
  .featureCard {
    width: 100%;
    max-width: 350px;
  }
  
  .heroTitle {
    font-size: 32px;
  }
  
  .loginContainer {
    padding: 20px 15px;
  }
  
  .heroSection {
    margin-top: 10px;
  }
  
  .consentLabel {
    padding: 10px;
  }
}
