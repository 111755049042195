/* src/css/AuthCallback.module.css */

/* Overall page container:
   - Same background as the Login page
   - Centers the content
*/
.authCallbackContainer {
    width: 100%;
    min-height: 100vh;
    background-color: #E0E1EB; /* Matches feedaudit background */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  /* White box container for the loading element:
     - Rounded corners, subtle shadow
     - Centered text
  */
  .authCallbackBox {
    background-color: #ffffff;
    width: 90%;
    max-width: 400px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Lexend Deca', sans-serif;
    box-sizing: border-box;
  }
  
  /* Loading text styling:
     - Uses DM Serif Text for a modern look
     - Slightly larger font for prominence
  */
  .loadingText {
    font-family: 'DM Serif Text', serif;
    font-size: 24px;
    color: #4758EB;
    margin-top: 20px;
  }
  